<template>
  <div class="column m-t-2 step-fields">
    <div class="ui step-wrap">
      <div class="step-left">
        <Ingredients
          :group="this.group"
          :step="this.step"
          :step-endpoint="stepEndpoint"
        />
      </div>

      <div class="step-right">
        <div class="ui form">
          <div class="field">
            <vue2-tinymce-editor
              v-if="editorType === 'wysiwyg'"
              v-model.lazy="description"
              class="maxLines"
              :options="editorOptions"
              :placeholder="
                capitalize($i18n.t('activerecord.attributes.step.description'))
              "
              @editorChange="updateDescription"
            />
            <textarea
              v-else
              v-model.lazy="description"
              class="maxLines"
              :placeholder="
                capitalize($i18n.t('activerecord.attributes.step.description'))
              "
              @change="updateDescription"
            />
          </div>

          <IcookinFields
            :step="step"
            :step-endpoint="stepEndpoint"
            v-if="isIcookable"
          />
          <Accessories
            :step="step"
            :step-endpoint="stepEndpoint"
            v-if="isIcookable"
          />
        </div>
      </div>

      <div class="step-remove">
        <a class="custom-link--remove" @click="confirmDeletion">
          <i class="gdm-supprimer icon"></i>
          <span>{{
            capitalize($i18n.t('elements.recipe.buttons.del_step'))
          }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue2TinymceEditor } from 'vue2-tinymce-editor'
import { mapGetters } from 'vuex'
import { inflection, api } from './mixins'

import Ingredients from './Ingredients.vue'
import IcookinFields from './IcookinFields.vue'
import Accessories from './Accessories.vue'

export default {
  props: ['group', 'step'],
  mixins: [inflection, api],
  components: { Ingredients, IcookinFields, Accessories, Vue2TinymceEditor },
  data() {
    return {
      description: this.step.description,
      editorOptions: {
        menubar: false,
        statusbar: false,
        language: 'fr_FR',
        plugins: 'autoresize autolink hr link lists image media',
        toolbar1:
          'bold italic underline strikethrough blockquote | superscript subscript | forecolor backcolor | bullist numlist  | link hr',
        toolbar2:
          'formatselect | alignleft aligncenter alignright alignjustify | image | media',
      },
      editorType: $('#step-editor').data('editor'),
    }
  },
  computed: {
    ...mapGetters(['isIcookable']),
    stepEndpoint() {
      return `${this.recipePath}/groups/${this.group.id}/steps/${this.step.id}`
    },
  },
  methods: {
    updateDescription() {
      this.sendRequest('put', this.stepEndpoint, {
        description: this.description,
      }).then((response) => {
        this.description = response.data.description
      })
    },
    confirmDeletion() {
      this.$store.dispatch('openDeleteConfirmation', {
        resource: 'step',
        path: this.stepEndpoint,
      })
    },
  },
}
</script>

<style lang="scss">
body {
  margin: 0 !important;
}
</style>
